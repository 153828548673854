<template>
  <div class="py-2">
    <BPageLoader v-if="isLoading && !data?.metaData?.offset" />
    <div v-else-if="data.blessors?.length">
      <div
        class="flex justify-between font-semibold text-lg py-3 text-text-alternate-1"
      >
        <h2>Blessors</h2>
        <p>
          <span class="underline">
            {{ data.blessors.length }}
          </span>
        </p>
      </div>
      <BBlessor
        v-for="blessor in data.blessors"
        :key="blessor.id"
        :blessor="blessor"
      />
      <div v-if="isLoading" class="flex w-full justify-center py-5">
        <BButtonLoader class="h-6 w-6" />
      </div>
      <button
        v-else-if="!data.metaData.isLastPage"
        class="border border-btn-border-secondary items-center w-full px-4 py-3.5 xs:py-4 text-center rounded text-text-alternate-1 font-semibold text-sm mt-4 mb-2"
        @click="handleShowMore"
      >
        Show more
      </button>
      <div class="border-t border-border-primary mt-4 pt-2">
        <button
          class="bg-btn-bg-alternate-1 text-btn-text-alternate-1 w-full py-4 rounded text-center text-sm xs:text-base font-bold my-2"
          @click="$emit('verify')"
        >
          Verify Your Donation
        </button>
      </div>
    </div>
    <div
      class="flex flex-col items-center text-center px-6 pt-6 pb-10 text-text-primary"
      v-else
    >
      <BlesserIcon class="my-8" />
      <h3 class="py-2 font-semibold text-lg">No Blessors</h3>
      <p class="px-5 py-2 text-xs">
        This fundraiser has not received any blessings yet.
      </p>
    </div>
  </div>
</template>

<script>
import { toRefs, ref } from 'vue';
import randomColor from 'randomcolor';
import { useApi } from '@/cmp-functions/useApi';
import { getFundraiserBlessings } from '@/services/api';
import BBlessor from '@/components/ui/BBlessor';
import BPageLoader from '@/components/ui/BPageLoader';
import BButtonLoader from '@/components/ui/BButtonLoader';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: blessors, metadata } = data;

  return {
    blessors: [...pre.blessors, ...blessors],
    metaData: {
      offset: blessors.length + pre.blessors.length,
      ...metadata,
    },
  };
};

export default {
  name: 'Blessors',

  props: ['fundraiser', 'authUser', 'isExpired'],

  components: { BPageLoader, BButtonLoader, BBlessor },

  emits: ['share', 'bless', 'verify'],

  setup(props) {
    const [response, getBlessings] = useApi(
      getFundraiserBlessings,
      {
        blessors: [],
        metaData: { offset: 0 },
      },
      handlePaginationResponse
    );

    getBlessings({
      fundraiserId: props.fundraiser.id,
      offset: response.data.metaData.offset,
    });

    const handleShowMore = () => {
      const offset = response.data.metaData.offset;
      getBlessings({ fundraiserId: props.fundraiser.id, offset });
    };

    const verify = ref(false);

    return { verify, randomColor, handleShowMore, dayjs, ...toRefs(response) };
  },
};
</script>
